export const AppActions = {

  ADD_GLOBAL_CLASS: 'ADD_GLOBAL_CLASS',

  REMOVE_GLOBAL_CLASS: 'REMOVE_GLOBAL_CLASS',

  DISABLE: 'DISABLE',

  ENABLE: 'ENABLE',

  CLEAR: 'CLEAR',

  DOWNLOAD: 'DOWNLOAD',

  MAGIC_DRAWING: 'MAGIC_DRAWING',

  CHANGE_COLOR: 'CHANGE_COLOR',

  CHANGE_RULER_MODE: 'CHANGE_RULER_MODE',

  CHANGE_CURSOR_MODE: 'CHANGE_CURSOR_MODE',

};
